
import { OrderedListOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import axios from 'axios';
import { Vue, Options } from 'vue-class-component';
import infoComponentVue from '../components/info.component.vue';
import { ref } from 'vue';

interface FileItem {
    uid: string;
    name?: string;
    status?: string;
    response?: string;
    url?: string;
    preview?: string;
    originFileObj?: any;
    file: string | Blob;
}

@Options({
    components: {
        'info': infoComponentVue,
        'extension-table-outlined': OrderedListOutlined,
        'upload-outlined': UploadOutlined
    }
})
export default class ExtensionTable extends Vue {
    loading = true;
    fileList = ref<FileItem[]>([]);
    csvFile: any = null;
    csvFileLabel: any = '';
    password_export: any = '';
    checkPasswordExport = false;
    loadingUpload = false;
    labelCol = {
        span: 10,
    };
    wrapperCol = {
        span: 13
    };
    updatingRow = false;
    columns = [
        {
            title: 'PLU',
            dataIndex: 'plu',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Company Code',
            dataIndex: 'company_code',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Store Code',
            dataIndex: 'store_code',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Mall Code',
            dataIndex: 'mall_code',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Mall Name',
            dataIndex: 'mall_name',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Product ID',
            dataIndex: 'product_id',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        }, 
        {
            title: 'Options',
            dataIndex: 'options',
            slots: {
                customRender: 'viewMore',
            }
        }
    ];
    rows = [];
    loadingTable = true;
    pagination = {
        current: 1,
        pageSize: 10,
        total: 1
    };
    showModal = false;
    showModalExport = false;
    viewingRow = null;
    selectedRows: any = [];
    rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            this.selectedRows = selectedRows;
        }
    };
    exportLoading = false;
    sortBy = 'plu';
    sortOrder = 'asc';
    pluNumber = '';
    companyCode = '';
    storeCode = '';
    mallCode = '';
    mallName = '';
    sku = '';
    productId = '';
    editRow: any = {};
    exportFilteredRowsLoading = false;
    exportType = '';
    exporting = false;

    async exportFilteredRows() {
        this.exportFilteredRowsLoading = true;
        const requestUrl = `${process.env.VUE_APP_API_URL!}/extension-table-V2/issue-export-filter-rid`;

        try {
            const res = await axios.get(requestUrl, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = res.data;
            this.exportFilteredRowsLoading = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-filter?rid=${data.request_id}`;
                if (this.pluNumber.length > 0) {
                    url += '&plu_number=' + this.pluNumber;
                }
                if (this.companyCode.length > 0) {
                    url += '&company_code=' + this.companyCode;
                }
                if (this.storeCode.length > 0) {
                    url += '&store_code=' + this.storeCode;
                }
                if (this.sku.length > 0) {
                    url += '&sku=' + this.sku;
                }
                if (this.productId.length > 0) {
                    url += '&product_id=' + this.productId;
                }

                const requestResponse: any = await axios.get(url);
                const responseData: any = await requestResponse.data;
                message.info(responseData.message);
            }
        } catch (error) {
            console.log(error);
            this.exportFilteredRowsLoading = false;
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    async exportSelectedRows() {
        this.exportLoading = true;
        let keys = '';
        this.selectedRows.forEach((row: any) => {
            keys += row.store_item_master_id;
            keys += ',';
        });
        const url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-selected-rows?keys=${keys}`;
        
        try {
            const res = await axios.get(url, {
                headers: {
                    APIToken: window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)
                }
            });
            const data = await res.data;
            this.exportLoading = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully exported selected rows!');
                const encodeUri = encodeURI("data:text/csv;charset=utf-8," + data.csv_string);
                window.open(encodeUri, '_blank');
            }
        } catch (error) {
            this.exportLoading = false;
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    async checkPassword() {
        this.checkPasswordExport = true;
        try {
            const fd = new FormData();
            fd.append('password', this.password_export);
            const res = await axios.post(`${process.env.VUE_APP_API_URL!}/extension-table-V2/check-password-export`, fd , {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });

            const data = await res.data;
            this.checkPasswordExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                this.exportCsvToFtp();
            }
        } catch (error) {
            console.log(error);
            this.checkPasswordExport = false;
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    async showViewModal(data: any) {
        const record: any = await this.getBoxedItemMaster(data.id, data.store_item_master_id);

        this.editRow.id = record[0].id;
        this.editRow.store_item_master_id = record[0].store_item_master_id;
        this.editRow.plu = record[0].plu;
        this.editRow.company_code = record[0].company_code;
        this.editRow.store_code = record[0].store_code;
        this.editRow.product_id = record[0].product_id;
        this.editRow.product_name = record[0].product_name;
        this.editRow.variant_options = record[0].variant_options;
        this.editRow.sku = record[0].sku;
        this.editRow.name_eng = record[0].name_eng;
        this.editRow.extended_name_eng = record[0].extended_name_eng;
        this.editRow.brand_eng = record[0].brand_eng;
        this.editRow.long_description_eng = record[0].long_description_eng;
        this.editRow.ingredients_eng = record[0].ingredients_eng;
        this.editRow.category_eng = record[0].category_eng;
        this.editRow.price_ringgit = record[0].price_ringgit;
        this.editRow.sale_price_ringgit = record[0].sale_price_ringgit;
        this.editRow.quantity = record[0].quantity;
        this.editRow.is_chilled = record[0].is_chilled;
        this.editRow.is_frozen = record[0].is_frozen;
        this.editRow.weight = record[0].weight;
        this.editRow.length = record[0].length;
        this.editRow.width = record[0].width;
        this.editRow.height = record[0].height;
        this.editRow.volume = record[0].volume;
        this.editRow.is_age_gated = record[0].is_age_gated;
        this.editRow.non_halal = record[0].non_halal;
        this.editRow.max_cart_quantity = record[0].max_cart_quantity;
        this.editRow.unit_count = record[0].unit_count;
        this.editRow.unit_type = record[0].unit_type;
        this.editRow.is_perishable = record[0].is_perishable;
        this.editRow.is_consignment = record[0].is_consignment;
        this.editRow.product_origin = record[0].product_origin;
        this.editRow.product_grade = record[0].product_grade;
        this.editRow.product_size = record[0].product_size;
        this.editRow.upc = record[0].upc;
        this.editRow.is_published = record[0].is_published;
        this.editRow.tax_rate = record[0].tax_rate;
        this.editRow.filler_1 = record[0].filler_1;
        this.editRow.filler_2 = record[0].filler_2;
        this.editRow.filler_3 = record[0].filler_3;
        this.editRow.filler_4 = record[0].filler_4;
        this.editRow.filler_5 = record[0].filler_5;
        this.editRow.start_publish_date = record[0].start_publish_date;
        this.editRow.end_publish_date = record[0].end_publish_date;
        this.editRow.preset_publish = record[0].preset_publish;
        this.editRow.preset_quantity = record[0].preset_quantity;
        this.editRow.image_links = record[0].image_links;
        this.editRow.mall_code = record[0].mall_code;

        this.showModal = true;
    }

    async getBoxedItemMaster(id: any, store_item_master_id: any) {
        console.log(id);
        try {
            const res = await axios.get(`${process.env.VUE_APP_API_URL!}/extension-table-V2/show?item_master_id=${id}&store_item_master_id=${store_item_master_id}`, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });

            const data = await res.data;
            if (data.error) {
                message.error(data.error_message);
            }

            return data.extension_table
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    async updateRow() {
        this.updatingRow = true;
        try {
            const res = await axios.post(`${process.env.VUE_APP_API_URL!}/extension-table-V2/update`, this.editRow, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });

            const data = await res.data;
            this.updatingRow = false;
            if (data.error) {
                if (data.error_type == 'validation') {
                    let firstKey: any = null;
                    Object.keys(data.errors).forEach(key => {
                        if (firstKey == null) {
                            firstKey = key;
                        }
                    });
                    message.error(firstKey + ' : ' + data.errors[firstKey]);
                } else {
                    message.error(data.error_message);
                }
            } else {
                message.success('Successfully updated row!');
                this.getStagingTable();
                this.showModal = false;
            }
        } catch (error) {
            console.log(error);
            this.updatingRow = false;
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    async uploadCsv() {
        try {
            const fd = new FormData();
            fd.append('file', this.csvFile);

            this.loadingUpload = true;
            const res = await axios.post(`${process.env.VUE_APP_API_URL!}/extension-table-V2/upload-excel`, fd, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.loadingUpload = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully uploaded CSV File! Please check back shortly.');
            }
        } catch (error) {
            this.loadingUpload = false;
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    beforeUpload = (file: FileItem) => {
        this.csvFile = file;
        this.csvFileLabel = file.name;
        return false;
    }

    handleTableChange(event: any, filters: any, sorter: any) {
        const current = event.current;
        this.pagination.current = current;
        this.sortBy = sorter.field;
        this.sortOrder = sorter.order;
        this.loadingTable = true;
        this.getStagingTable();
    }

    async doExport() {
        const exportType = this.exportType;
        switch(exportType) {
            case 'category':
                await this.exportCategoryCsvToFtp();
                break
            case 'enrichment_full':
                await this.exportEnrichmentCsvToFtp();
                break
            case 'enrichment_yesterday':
                await this.exportYesterdayEnrichmentCsvToFtp();
                break
            case 'inventory':
                await this.exportInventoryCsvToFtp();
                break
            case 'pricing':
                await this.exportPricingCsvToFtp();
                break
            case 'today_product':
                await this.exportTodayProductCsvToFtp();
                break
            default:
                message.error('Please select file type to export!');
            break;
        }
    }

    async exportCsvToFtp() {
        this.checkPasswordExport = true;
        try {
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-to-ftp`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.checkPasswordExport = false;
            this.showModalExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully queued export!');
            }
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    async exportCategoryCsvToFtp() {
        this.checkPasswordExport = true;
        this.exporting = true;
        try {
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-category-to-ftp`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.checkPasswordExport = false;
            this.showModalExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully queued export!');
            }
            this.exporting = false;
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
            this.exporting = false;
        }
    }

    async exportEnrichmentCsvToFtp() {
        this.checkPasswordExport = true;
        this.exporting = true;
        try {
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-enrichment-to-ftp`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.checkPasswordExport = false;
            this.showModalExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully queued export!');
            }
            this.exporting = false;
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
            this.exporting = false;
        }
    }

    async exportYesterdayEnrichmentCsvToFtp() {
        this.checkPasswordExport = true;
        this.exporting = true;
        try {
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-yesterday-enrichment-to-ftp`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.checkPasswordExport = false;
            this.showModalExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully queued export!');
            }
            this.exporting = false;
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
            this.exporting = false;
        }
    }

    async exportTodayProductCsvToFtp() {
        this.checkPasswordExport = true;
        this.exporting = true;
        try {
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-today-product-to-ftp`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.checkPasswordExport = false;
            this.showModalExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully queued export!');
            }
            this.exporting = false;
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
            this.exporting = false;
        }
    }

    async exportInventoryCsvToFtp() {
        this.checkPasswordExport = true;
        this.exporting = true;
        try {
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-inventory-to-ftp`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.checkPasswordExport = false;
            this.showModalExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully queued export!');
            }
            this.exporting = false;
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
            this.exporting = false;
        }
    }

    async exportPricingCsvToFtp() {
        this.checkPasswordExport = true;
        this.exporting = true;
        try {
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2/export-pricing-to-ftp`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.checkPasswordExport = false;
            this.showModalExport = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully queued export!');
            }
            this.exporting = false;
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
            this.exporting = false;
        }
    }

    async deleteRow(row: any) {
        if (confirm('Are you sure you want to delete this record?')) {
            row.deleteLoading = true;
            try {
                const fd = new FormData();
                fd.append('id', row.store_item_master_id);
                const res = await axios.post(`${process.env.VUE_APP_API_URL!}/extension-table-V2/delete`, fd, {
                    headers: {
                        APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`,
                        'Content-Type': 'application/json',
                    }
                });
                const data = res.data;
                row.deleteLoading = false;
                if (data.error) {
                    message.error(data.error_message);
                } else {
                    message.success('Successfully deleted row!');
                    this.getStagingTable();
                }
            } catch (error) {
                row.deleteLoading = false;
                console.log(error);
                message.error('Sorry, something went wrong. Please check your internet connection and try again later.');
            }
        }
    }

    searchTable() {
        this.pagination.current = 1;
        this.getStagingTable();
    }

    async getStagingTable() {
        try {
            this.loadingTable = true;
            let url = `${process.env.VUE_APP_API_URL!}/extension-table-V2?page=${this.pagination.current}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}`;
            
            if (this.pluNumber.length > 0) {
                url += '&plu_number=' + this.pluNumber;
            }
            if (this.companyCode.length > 0) {
                url += '&company_code=' + this.companyCode;
            }
            if (this.storeCode.length > 0) {
                url += '&store_code=' + this.storeCode;
            }
            if (this.mallCode.length > 0) {
                url += '&mall_code=' + this.mallCode;
            }
            if (this.mallName.length > 0) {
                url += '&mall_name=' + this.mallName;
            }
            if (this.sku.length > 0) {
                url += '&sku=' + this.sku;
            }
            if (this.productId.length > 0) {
                url += '&product_id=' + this.productId;
            }

            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            if (data.error) {
                message.error(data.error_message);
            } else {
                data.extension_table.data.forEach((row: any) => {
                    row.key = row.id;
                    row.deleteLoading = false;
                });
                const currentPage = this.pagination.current;
                this.pagination = {
                    current: currentPage,
                    pageSize: 10,
                    total: data.extension_table.count
                };
                this.loadingTable = false;
                this.rows = data.extension_table.data;
            }
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    showViewModalExport() {
        this.showModalExport = true;
        this.checkPasswordExport = false;
        this.password_export = '';
    }

    mounted() {
        console.log('[APP] Dashboard Mounted');
        this.getStagingTable();
    }
}
